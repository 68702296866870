var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"players"},[(!_vm.$route.params.player_id)?_c('sc-table',{attrs:{"table":_vm.players},scopedSlots:_vm._u([{key:"column-name",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"name","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('span',{staticClass:"player-uuid",staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(row.name))])])]}},{key:"column-session_uuid",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"session_uuid","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'session_uuid', '-'))+" ")])]}},{key:"column-players",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"players","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'connections', 0))+" ")])]}},{key:"column-users",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"users","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'users', 0))+" ")])]}},{key:"column-browsers",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"browsers","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'browsers', 0))+" ")])]}},{key:"column-actions",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"actions","table":_vm.players},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('sc-table-button',{staticStyle:{"margin-right":"0.5em"},attrs:{"icon":"redo"},on:{"click":function($event){return _vm.refreshSession(row.cm_player_uuid)}}}),_c('sc-table-button',{attrs:{"icon":"eye"},on:{"click":function($event){return _vm.view(row)}}})]},proxy:true}],null,true)})]}}],null,false,4179630708)}):_vm._e(),(_vm.$route.params.player_id)?_c('div',{staticClass:"player-view"},[_c('router-view',{key:_vm.$route.params.player_id,attrs:{"managerPlayer":_vm.socketPlayer()},on:{"refreshSession":_vm.refreshSession,"requestSchedule":_vm.requestSchedule}})],1):_vm._e()],1),(_vm.$user.isAtLeast(20))?_c('div',{staticClass:"server-logs-container",class:{hidden:!_vm.showLogs}},[_c('div',{staticClass:"server-log-header",on:{"click":function($event){_vm.showLogs = !_vm.showLogs}}},[_c('div',{staticClass:"name"},[_vm._v("Server logs")]),_c('div',{staticClass:"hide"},[(_vm.showLogs)?_c('span',[_vm._v("Hide")]):_c('span',[_vm._v("Show")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogs),expression:"showLogs"}],ref:"logs",staticClass:"server-logs"},_vm._l((_vm.logs),function(log){return _c('div',{staticClass:"log-line"},[_vm._v(" "+_vm._s(log.time)+" : "+_vm._s(log.message)+" ")])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }