import VueRouter from 'vue-router'

import app from '../views/app/app.vue';
import contentManager from '../views/app/content-manager/routes.js';
import manager from '../views/app/manager/routes.js';
import auth from '../views/auth/routes'

import home from '../views/app/home/routes'

const routes = [
  {
    path: '/',
    component: app,
    meta: {requiresAuth: false, navRoot: true},
    children: [
      home,
      contentManager,
      manager
    ]
  },
  ...auth,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
