<template>
    <div>
        <div class="actions pd pdb0">
            <sc-button icon="chevron-left" class="back" @click="$router.push('/manager')">Back
            </sc-button>
            <sc-button :icon="(sessionOnConnect?'check':'times')" @click="toggleSessionOnConnect">
                New session on connect
            </sc-button>
            <sc-button icon="redo" @click="$emit('refreshSession', player.cm_player_uuid)">
                Refresh session
            </sc-button>
        </div>

        <div class="pd pdb0">
            <div class="header">
                <div class="player-info">
                    <div class="sc-widget">
                        <div class="label">
                            Player
                        </div>
                        <div class="value">
                            <span v-if="player">{{ player['name'] }}</span>
                        </div>
                    </div>
                </div>
                <div class="connections">
                    <div class="info-rows sc-widgets" v-if="managerPlayer">
                        <div class="info-row sc-widget">
                            <div class="label">
                                Players
                            </div>
                            <div class="value">
                                {{ managerPlayer['connections'] }}
                            </div>
                        </div>
                        <div class="info-row sc-widget">
                            <div class="label">
                                Users
                            </div>
                            <div class="value">
                                {{ managerPlayer['users'] }}
                            </div>
                        </div>
                        <div class="info-row sc-widget" v-if="$user.isAtLeast(20)">
                            <div class="label">
                                Browsers
                            </div>
                            <div class="value">
                                {{ managerPlayer['browsers'] }}
                            </div>
                        </div>
                    </div>
                    <div class="info-rows not-connected" v-else>
                        Not connected to websocket
                    </div>
                </div>

            </div>
        </div>

        <div class="pd pdb0">
            <sc-tabs>
                <sc-tab name="traffic" icon="sitemap" to="traffic"></sc-tab>
                <sc-tab name="interactions" icon="chart-line" to="interactions"></sc-tab>
            </sc-tabs>
        </div>

        <router-view v-if="ready" :passed="player" :sessionOnConnect="sessionOnConnect"></router-view>
    </div>
</template>

<script>
export default {
  name: 'index',

  props: {
    managerPlayer: {}
  },

  data() {
    return {
      player: null,
      ready: false,
      sessionOnConnect: true,
    }
  },

  mounted() {
    this.fetch();
  },

  created() {
    if (localStorage.getItem('session-on-connect') === '0') {
      this.sessionOnConnect = false;
    }
  },

  methods: {
    fetch() {
      this.$talker.api(`players/${this.$route.params.player_id}`)
        .then(res => {
          this.player = res.data;
          this.ready = true;
        })
    },

    toggleSessionOnConnect() {
      if (this.sessionOnConnect) {
        this.sessionOnConnect = false;
        localStorage.setItem('session-on-connect', '0');
      } else {
        this.sessionOnConnect = true;
        localStorage.setItem('session-on-connect', '1');
      }
    },
  }

}
</script>

<style scoped lang="scss">

.actions {
    display: flex;

    > div {
        margin-right: 32px;
    }
}


.header {
    display: flex;

    .player-info {
        margin-right: 32px;
    }

    .connections {

        .sc-widgets {
            background: $color-tertiary;

            .sc-widget {
                background: $color-tertiary;
                box-shadow: none;
                color: #fff;
            }
        }

        .not-connected {
            background: $color-tertiary;
            color: #fff;
            font-weight: 700;
            padding: 24px 32px;
            border-radius: 0.3em;
            box-shadow: $shading;
        }
    }
}


</style>