<template>
    <div>
        <div class="players">
            <sc-table v-if="!$route.params.player_id" :table="players">

                <template #column-name="{row}">
                    <sc-table-cell field="name" :table="players" @click.native="view(row)">
                        <span class="player-uuid" style="font-weight:600;">{{ row.name }}</span>
                    </sc-table-cell>
                </template>

                <template #column-session_uuid="{row}">
                    <sc-table-cell field="session_uuid" :table="players" @click.native="view(row)">
                        {{ socketPlayerValue(row, 'session_uuid', '-') }}
                    </sc-table-cell>
                </template>
                <template #column-players="{row}">
                    <sc-table-cell field="players" :table="players" @click.native="view(row)">
                        {{ socketPlayerValue(row, 'connections', 0) }}
                    </sc-table-cell>
                </template>
                <template #column-users="{row}">
                    <sc-table-cell field="users" :table="players" @click.native="view(row)">
                        {{ socketPlayerValue(row, 'users', 0) }}
                    </sc-table-cell>
                </template>
                <template #column-browsers="{row}">
                    <sc-table-cell field="browsers" :table="players" @click.native="view(row)">
                        {{ socketPlayerValue(row, 'browsers', 0) }}
                    </sc-table-cell>
                </template>

                <template #column-actions="{row}">
                    <sc-table-cell field="actions" :table="players">
                        <template #actions>
                            <sc-table-button icon="redo" @click="refreshSession(row.cm_player_uuid)"
                                             style="margin-right:0.5em">
                            </sc-table-button>
                            <sc-table-button icon="eye" @click="view(row)">
                            </sc-table-button>
                        </template>
                    </sc-table-cell>

                </template>

            </sc-table>

            <div class="player-view" v-if="$route.params.player_id">
                <router-view :key="$route.params.player_id" @refreshSession="refreshSession"
                             @requestSchedule="requestSchedule" :managerPlayer="socketPlayer()"></router-view>
            </div>
        </div>

        <div class="server-logs-container" :class="{hidden:!showLogs}" v-if="$user.isAtLeast(20)">
            <div class="server-log-header" @click="showLogs = !showLogs">
                <div class="name">Server logs</div>
                <div class="hide">
                    <span v-if="showLogs">Hide</span>
                    <span v-else>Show</span>
                </div>
            </div>
            <div class="server-logs" ref="logs" v-show="showLogs">
                <div class="log-line" v-for="log in logs">
                    {{ log.time }} : {{ log.message }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ScButton from "@/components/common/sc-button";

export default {
  name: 'manager',
  components: {ScButton},
  data() {
    return {
      socket: null,
      socket_timeout: null,
      players: null,
      logs: [],
      managerToken: null,
      showLogs: true,
      rows: [],
      items: []
    }
  },

  mounted() {
    this.fetch();
    this.managerToken = this.$store.state.user.user.manager_token;
    this.startWebsocket();

    this.players = this.$stable.createTable({
      rows: () => {
        return this.items
      },
      columns: {
        id: {
          target: 'id',
          width: 60
        },
        name: {
          name: 'Player (click to view)',
          width: 350,
        },
        players: {width: 110},
        users: {width: 110},
        browsers: {width: 110, guard: (!this.$user.isAtLeast(20))},
        session_uuid: {
          name: 'latest session',
          width: 350,
          fill: true
        },
        actions: {width: 100,}
      }
    })
    this.players.hasData = true;
  },

  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    setTimeout(() => {
      this.socket = null;
      clearTimeout(this.socket_timeout);
    }, 1000)
  },

  methods: {

    fetch() {
      this.$talker.api('/players')
        .then(res => {
          this.items = res.data;
        })
    },

    view(player) {
      this.$router.push('/manager/player/' + player.id + '/traffic')
    },

    refreshSession(player) {
      this.send({type: 'refreshSession', data: {player_uuid: player}})
    },

    requestSchedule(player) {
      this.send({type: 'requestSchedule', data: {player_uuid: player}})
    },

    socketPlayer() {
      return this.rows.find(x => x.id === parseInt(this.$route.params.player_id))
    },

    socketPlayerValue(row, field, placeholder = 'none') {
      let player = this.rows.find(x => x.id === row.id)
      if (player && player[field]) {
        return player[field];
      }
      return placeholder;
    },

    startWebsocket() {
      if ("WebSocket" in window) {

        // open the websocket connection
        this.socket = new WebSocket(process.env.VUE_APP_SOCKET_URL + '?type=manager&manager_token=' + this.managerToken);

        this.socket.onmessage = (evt) => {

          let message = JSON.parse(evt.data)

          if (message.type === 'getPlayers') {
            this.rows = message.data
          }

          if (message.type === 'server-log') {
            this.addLog(message)
          }

          if (message.type === 'ping') {
            this.socket.send(JSON.stringify({type: 'pong'}))
          }
        };

        this.socket.onopen = (evt) => {
          console.log('open!')
        };

        this.socket.onclose = (e) => {
          console.log('Socket is closed. Reconnect will be attempted in 5 second.', e.reason);
          clearTimeout(this.socket_timeout);
          this.socket_timeout = setTimeout(() => {
            // this.startWebsocket();
          }, 5000);
        };
      } else {
        console.log("WebSocket NOT supported by your Browser!");
      }
    },

    send(message) {
      this.socket.send(JSON.stringify(message))
    },

    addLog(message) {
      this.logs.push(message.data)
      this.$nextTick(() => {
        if (this.$refs.logs) {
          let objDiv = this.$refs.logs;
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      })
    }
  }
}
</script>

<style lang="scss">

.server-logs-container {
    position: fixed;
    max-width: 600px;
    right: 10px;
    width: 100%;
    bottom: 10px;
    border-radius: 0.5em;
    background: rgba(0, 0, 0, 0.8);
    color: #efefef;
    z-index: 10;

    font-weight: 600;
    font-family: monospace;

    .server-log-header {
        padding: 0.2em 0.5em 0.5em;
        display: flex;

        .name {
            flex: 1;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;

            .hide {
                text-decoration: underline;
            }
        }
    }

    .server-logs {
        min-height: 100px;
        max-height: 250px;
        overflow-y: auto;
        padding: 0.5em;

        &::-webkit-scrollbar {
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    &.hidden {
        bottom: 0;
        right: 0;
        border-radius: 0.3em 0 0 0;
    }
}

.player-uuid:hover {
    cursor: pointer;
    text-decoration: underline;
}
</style>