<template>
    <div id="app">
        <notification-panel></notification-panel>
        <router-view></router-view>
    </div>
</template>

<script>
import ChildrenRoute from "@/components/common/children-route";

export default {
  name: 'root',
  components: {ChildrenRoute},
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap');
@import '@/design/_reset';
@import '@/design/_globals';

#app {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    position: relative;
}
</style>
