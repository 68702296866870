<template>
    <div>
        <div class="buttons width-container pd pdb0" v-if="$user.isAtLeast(10)">
            <sc-button @click="$router.push({path:'new',append:true})" icon="plus">
                Add new
            </sc-button>
        </div>
        <div class="width-container">
            <sc-table :table="table">

                <template #column-players="{row}">
                    <sc-table-cell :table="table" field="players">
                        {{ row.players.length }} player<span v-if="row.players !== 1">s</span>
                    </sc-table-cell>
                </template>

                <template #column-actions="{row}">
                    <sc-table-cell :table="table" field="actions" :padded="false" :clickable="false">
                        <template #actions>
                            <sc-table-button icon="edit" @click.native="edit(row)" v-if="$user.isAtLeast(10)">
                            </sc-table-button>
                            <sc-table-button style="margin-left:0.5em;" icon="sync" @click.native="sync(row)">
                            </sc-table-button>
                        </template>
                    </sc-table-cell>
                </template>

            </sc-table>
        </div>
    </div>

</template>

<script>

export default {
  name: 'cmAll',
  data() {
    return {
      table: this.$stable.createTable({
        url: '/content-manager',
        sortBy: 'name',
        columns: {
          name: {fill: true,},
          players: {
            sort: false,
          },
          actions: {
            align: 'right',
          },
        },
      }),
    }
  },

  mounted() {
    this.table.fetch();
  },

  methods: {
    view(item) {
      this.$router.push({path: `/content-managers/${item.id}/details`})
    },

    edit(item) {
      this.$router.push({path: `/content-managers/${item.id}/edit`})
    },

    sync(item) {
      this.$talker.api.put(`/content-manager/${item.id}/sync`)
        .then(res => {
          this.$notify.success('Media / Players sync started')
        })
    }
  }
}
</script>

<style scoped lang="scss">


</style>