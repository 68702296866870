<template>
    <div class="pdw">
        <div class="pdh">
            <sc-datepicker :date-range.sync="dateRange"></sc-datepicker>
        </div>
        <chart-interactions :player_id="$route.params.player_id" :date-range="dateRange"></chart-interactions>
        <div class="pdh" style="max-width:800px">
            <chart-top-media :player_id="$route.params.player_id" :date-range="dateRange"></chart-top-media>
        </div>
    </div>
</template>

<script>
import ChartInteractions from "@/components/chart-interactions";
import moment from "moment-timezone";
import ScDatepicker from "@/components/sc-datepicker";
import ChartTopMedia from "@/components/chart-top-media";

export default {
  name: "interactions",
  components: {ChartTopMedia, ScDatepicker, ChartInteractions},

  data() {
    return {
      dateRange: {
        startDate: moment().subtract('1', 'month'),
        endDate: moment(),
      }
    }
  }

}
</script>