<template>
    <div class="banner">
        <div class="header pdt">
            <div class="logo" @click="$router.push('/')">
                <!--                <img src="@/img/logo-black.png"/>-->
                <div class="name top">Scala</div>
                <div class="name bottom">Director</div>
            </div>
        </div>
        <div class="sub-header pd" v-if="page">
            {{ page }}
        </div>
    </div>
</template>

<script>

export default {
  name: "sc-page-header",
  data() {
    return {}
  },

  computed: {
    page() {
      if (this.$route.meta.name) {
        return this.$route.meta.name
      } else {
        return this.findLatestName();
      }
    }
  },

  methods: {
    findLatestName() {
      let items = this.$route.matched;
      for (let i = items.length - 1; i >= 0; i--) {
        if (items[i].meta.name) {
          return items[i].meta.name;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.banner {
    box-shadow: 2px 2px 10px 1px rgba(100, 100, 100, 0.25);
}


.header {
    background: #fff;
    display: flex;
    justify-content: flex-start;

    .logo {
        height: 40px;
        display: flex;
        //align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8em;

        .name {
            line-height: 1em;
            letter-spacing: 0.01em;
            font-weight: 700;

            &.top {
                margin-bottom: 0.4em;
            }

            &.bottom {
                font-size: 1.5em;
                text-transform: uppercase;
            }
        }
    }
}

.sub-header {
    font-weight: 700;
    font-size: 1.8em;
    text-transform: uppercase;
    background: $color-primary;
    color: #fff;
}
</style>