<template>
    <div>
        <div class="columns">
            <div class="col" v-if="$user.isAtLeast(20)">
                <sc-table :table="messages">

                    <template #column-data="{row}">
                        <sc-table-cell :table="messages" field="data">

                            <div class="qr-codes" v-if="row.type === 'newSession'">
                                <div class="qr-code-item">
                                    <div class="label">qr code 1</div>
                                    <a target="_blank" style="text-decoration: underline"
                                       :href="row.data.qr_code_1.url">{{ row.data.qr_code_1.url }}</a>
                                    <div>
                                        <img class="qr-code" v-if="row.data.qr_code_1"
                                             :src="'data:image/png;base64,' + row.data.qr_code_1.code"/>
                                    </div>
                                </div>
                                <div class="qr-code-item">
                                    <div class="label">qr code 4 (main)</div>
                                    <a target="_blank" style="text-decoration: underline"
                                       :href="row.data.qr_code_4.url">{{ row.data.qr_code_4.url }}</a>
                                    <div>
                                        <img class="qr-code" v-if="row.data.qr_code_4"
                                             :src="'data:image/png;base64,' + row.data.qr_code_4.code"/>
                                    </div>
                                </div>
                            </div>

                            <div style="white-space: pre-wrap;word-break: break-all" v-if="row.type !== 'newSession'">
                                {{ row }}
                            </div>


                        </sc-table-cell>

                    </template>

                    <template #column-actions="{row}">
                        <sc-button @click="acceptSession(row)" v-if="row.type === 'newSession'" color="secondary">Accept
                            session
                        </sc-button>
                        <sc-button @click="videoPlaying(row)" v-if="row.type === 'playVideo'" color="secondary">Play
                            Video
                        </sc-button>
                    </template>

                </sc-table>
            </div>
            <div class="col">
                <sc-table :table="eventsTable">

                    <template #rows>

                        <div v-for="event in eventsTable.rows()">

                            <sc-table-row :style="{borderBottom:(event.children.length > 0)?'0':''}">
                                <sc-table-cell :table="eventsTable" field="name">{{ event.name }}</sc-table-cell>
                                <sc-table-cell :table="eventsTable" field="source">
                                    {{ event.source }}
                                </sc-table-cell>
                                <sc-table-cell :table="eventsTable" field="additional">
                                    <div style="white-space:pre-wrap">{{ event.additional }}</div>
                                </sc-table-cell>
                                <sc-table-cell :table="eventsTable" field="created_at" :padded="false">
                                    {{ event.created_at | toDateHuman }}
                                    <template #sub>{{ event.created_at | toDatetime }}</template>
                                </sc-table-cell>
                            </sc-table-row>

                            <div class="event-children-container" v-if="event.children.length > 0">
                                <!--                                <div class="event-children-label">-->
                                <!--                                    <awesome-icon icon="caret-down" class="icon"/>-->
                                <!--                                    Session events-->
                                <!--                                </div>-->
                                <div class="event-children">
                                    <div v-for="child in event.children">
                                        <div class="child"
                                             :class="{childWithChildren:child.children.length > 0 || child.expect_response === 1}">
                                            <sc-table-row>
                                                <sc-table-cell :table="eventsTable" field="name">{{
                                                        child.name
                                                    }}
                                                </sc-table-cell>
                                                <sc-table-cell :table="eventsTable" field="source">
                                                    {{ child.source }}
                                                </sc-table-cell>
                                                <sc-table-cell :table="eventsTable" field="additional">
                                                    {{ child.additional }}
                                                </sc-table-cell>
                                                <sc-table-cell :table="eventsTable" field="created_at" :padded="false">
                                                    {{ child.created_at | toDateHuman }}
                                                    <template #sub>{{ child.created_at | toDatetime }}</template>
                                                </sc-table-cell>
                                            </sc-table-row>
                                        </div>
                                        <div v-if="child.children.length > 0 || child.expect_response === 1"
                                             class="responses">
                                            <div v-if="child.children.length > 0"
                                                 v-for="(subchild,index) in child.children">
                                                <div class="subchild"
                                                     :class="{notLast:index + 1 < (child.children.length)}">
                                                    <sc-table-row>
                                                        <sc-table-cell :table="eventsTable" field="name">{{
                                                                subchild.name
                                                            }}
                                                        </sc-table-cell>
                                                        <sc-table-cell :table="eventsTable" field="source">
                                                            {{ subchild.source }}
                                                        </sc-table-cell>
                                                        <sc-table-cell :table="eventsTable" field="additional">
                                                            {{ subchild.additional }}
                                                        </sc-table-cell>
                                                        <sc-table-cell :table="eventsTable" field="created_at"
                                                                       :padded="false">
                                                            {{ subchild.created_at | toDateHuman }}
                                                            <template #sub>{{
                                                                    subchild.created_at | toDatetime
                                                                }}
                                                            </template>
                                                        </sc-table-cell>
                                                    </sc-table-row>
                                                </div>
                                            </div>
                                            <div v-if="child.children.length === 0" class="subchild bad">
                                                <div class="no-response">
                                                    No response
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </template>

                </sc-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'traffic',

  props: {
    passed: {},
    sessionOnConnect: {}
  },

  data() {
    return {
      socket: null,
      socket_timeout: null,
      messages: this.$stable.createTable({
        name: 'Websocket traffic',
        columns: {
          direction: {},
          type: {},
          data: {
            name:'full payload',
            fill: true,
          },
          actions: {}
        }
      }),
      player_id: '',

      events: [],
      eventsTimeout: null,
      eventsTable: this.$stable.createTable({
        name: 'API events (10 latest)',
        columns: {
          name: {
            name: 'event',
            width: 150,
          },
          source: {width: 100},
          additional: {
            fill: true
          },
          created_at: {
            type: 'date'
          }
        }
      })
    }
  },

  mounted() {
    this.messages.data = [];

    this.player_id = this.passed.cm_player_uuid;

    if (this.$user.isAtLeast(20)) {
      this.startWebsocket();
    }
    this.getEvents();
  },

  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    setTimeout(() => {
      this.socket = null;
      clearTimeout(this.socket_timeout);
    }, 1000)

    clearTimeout(this.eventsTimeout)
  },


  methods: {

    getEvents() {
      this.$talker.api(`players/${this.player_id}/events`)
        .then(res => {
          this.eventsTable.data = res.data;
          this.eventsTable.hasData = true;
          this.eventsTable.prefetch();
          this.eventsTimeout = setTimeout(() => {
            this.getEvents();
          }, 5000)
        })
    },

    acceptSession(row) {
      if (!this.socket) {
        this.$notify.error('No socket connection')
        return;
      }
      let input = {
        type: 'logEvent',
        data: {
          event_id: row.data.event_id,
          event_name: 'sessionAccepted',
        }
      }
      this.socket.send(JSON.stringify(input))
    },

    videoPlaying(row) {
      if (!this.socket) {
        this.$notify.error('No socket connection')
        return;
      }
      let input = {
        type: 'logEvent',
        data: {
          event_id: row.data.event_id,
          event_name: 'videoPlaying',
          additional: {
            video: row.data.video
          }
        }
      }
      this.socket.send(JSON.stringify(input))
    },

    startWebsocket() {
      if ("WebSocket" in window) {

        // open the websocket connection
        let url = process.env.VUE_APP_SOCKET_URL + '?type=browser-player&debug=true&uuid=' + this.player_id;
        if (this.sessionOnConnect === false) {
          url += '&prevent=1';
        }
        this.socket = new WebSocket(url);

        this.socket.onmessage = (evt) => {

          let message = JSON.parse(evt.data)
          console.log('heard this message:', message);

          if (message.type === 'error') {
            this.$notify.error(message.data)
          }

          if (message.type === 'ping') {
            this.socket.send(JSON.stringify({type: 'pong'}))
          } else {


            // save the message and show it
            this.messages.data.unshift(message);
            this.messages.hasData = true;
            this.messages.prefetch();
          }


        };

        this.socket.onopen = (evt) => {
          console.log('open!')
        };

        this.socket.onclose = (e) => {
          console.log('Socket is closed. Reconnect will be attempted in 5 second.', e.reason);
          clearTimeout(this.socket_timeout);
          this.socket_timeout = setTimeout(() => {
            // this.startWebsocket();
          }, 5000);
        };
      } else {
        console.log("WebSocket NOT supported by your Browser!");
      }
    }
  }
}
</script>

<style lang="scss">
.columns {
    display: flex;
    flex-wrap: wrap;

    .col {
        flex: 1;
    }
}

.event-children-container {
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
    position: relative;
    z-index: 1;

    .event-children-label {
        margin: 0 32px 0.4em;
        font-size: 0.8em;
        font-weight: 500;

        .icon {
            margin-right: 1.1em;
        }
    }

    .event-children {
        margin: 0 2em 1em;
        background: rgb(240, 240, 243);
        border: 1px solid #ddd;
        font-size: 0.9em;
        border-radius: 0.3em;
        position: relative;
        z-index: 1;

        .child.childWithChildren {
            position: relative;
            background: rgb(240, 240, 243);
        }

        .responses {
            position: relative;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                width: 34px;
                height: calc(100% - 7px);
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                background: rgb(240, 240, 243);
                box-sizing: border-box;
                top: -1px;
                left: 32px;
                border-radius: 0 0 0.3em 0.3em;
            }

            .subchild {
                position: relative;
                background: rgba(0, 0, 100, 0.03);
                padding-left: 60px;

                &::before {
                    content: '';
                    position: absolute;
                    width: 1.2em;
                    height: 1.2em;
                    background: #edfded;
                    border-radius: 0.2em;
                    border: 1px solid #aaa;
                    box-sizing: border-box;
                    left: 40px;
                    top: 25px;
                    transform: translate(0, -50%);
                    z-index: 1;
                }

                &.bad::before {
                    background: rgb(255, 180, 180);
                }

                .no-response {
                    padding: 1em 32px;
                    font-size: 0.9em;
                    font-weight: 500;
                }
            }
        }


    }
}


.qr-codes {
    display: flex;
    flex-wrap: wrap;
}

.qr-code-item {
    margin-right: 2em;
    margin-bottom: 1em;

    .label {
        font-weight: 700;
    }

    .qr-code {
        border: 1px solid blue;
    }
}


</style>