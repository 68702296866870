<template>
    <transition name="fade">
        <div class="app-container">
            <portal-target name="sc-modal" transition="fade-in-transition"></portal-target>
            <sc-nav @setMargin="setMargin"></sc-nav>
            <div class="main-container" :style="{marginLeft:margin + 'px'}">
                <sc-page-header></sc-page-header>
                <router-view v-if="ready"></router-view>
            </div>
        </div>
    </transition>
</template>

<script>
import ScPageHeader from "@/components/sc-page-header";

export default {
  name: 'app',
  components: {ScPageHeader},
  data() {
    return {
      margin: 230,
      ready: false,
    }
  },

  mounted() {
    this.$store.dispatch('user/getUser', {talker: this.$talker})
      .then(res => {
        this.ready = true;
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          this.$notify.error('Unauthorized');
          this.$talker.logout({router: this.$router})
            .catch(err => {
              console.error(err);
            })
        }
      });
  },

  methods: {
    setMargin(value) {
      this.margin = value;
    }
  }

}
</script>

<style scoped lang="scss">


.main-container {
    transition: margin-left 0.4s $easing;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity $easing $transition-duration;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>