<template>
    <div class="pd">
        <sc-datepicker :date-range.sync="dateRange"></sc-datepicker>
        <div class="pdh">
            <chart-interactions :date-range="dateRange"></chart-interactions>
        </div>
        <div style="display:flex;flex-wrap:wrap;">
            <chart-top-players style="flex:1;padding-right:16px;" :date-range="dateRange"></chart-top-players>
            <chart-top-media style="flex:1;padding-left:16px;" :date-range="dateRange"></chart-top-media>
        </div>
    </div>
</template>

<script>
import ChartInteractions from "@/components/chart-interactions";
import moment from "moment-timezone";
import ScDatepicker from "@/components/sc-datepicker";
import ChartTopPlayers from "@/components/chart-top-players";
import ChartTopMedia from "@/components/chart-top-media";

export default {
  name: "home",
  components: {ChartTopMedia, ChartTopPlayers, ScDatepicker, ChartInteractions},

  data() {
    return {
      dateRange: {
        startDate: moment().subtract('1', 'month'),
        endDate: moment(),
      }
    }
  }

}
</script>

