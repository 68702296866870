import index from './index.vue'
import traffic from './traffic.vue'
import interactions from './interactions.vue'

const routes = {
  path: 'player/:player_id',
  meta: {name: 'connection details'},
  component: index,
  children: [
    {
      path: 'traffic',
      meta: {name: 'connection details'},
      component: traffic,
    }, {
      path: 'interactions',
      meta: {name: 'connection details'},
      component: interactions,
    },
  ]
};

export default routes;
