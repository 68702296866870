<template>
    <div class="button-container" :class="{padded, tabInline,disabled,running}">
        <button class="button" :class="[color]" @click="$emit('click', $event)">
            <div class="text">
                <div class="icon" v-if="icon">
                    <awesome-icon :icon="icon"></awesome-icon>
                </div>
                <slot></slot>
            </div>
            <fade-in-transition>
                <div class="running" :class="[color]" v-if="running"></div>
            </fade-in-transition>
        </button>
    </div>
</template>

<script>
export default {
  name: "sc-button",
  props: {
    color: {},
    running: {},
    padded: {
      type: Boolean,
      default: false,
    },
    tabInline: {
      type: Boolean,
      default: false,
    },
    icon: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  }

}
</script>

<style scoped lang="scss">
button {
    font-size: inherit;
}

.button-container.padded {
    margin-right: 32px;
}

.button {
    border: none;
    padding: 12px 16px 12px 16px;
    background: #fff;
    color: rgb(107, 112, 133);
    box-shadow: $shading;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: none;
    overflow: hidden;
    margin: 0;
}

.button:hover {
    color: rgb(62, 65, 77);
}

.button.secondary {
    background: $color-primary;
    color: #f0f0f0;
}

.button.secondary:hover {
    color: #fff;
}

.button.dark {
    background: $color-secondary;
    color: #fff;
}

.button:active {
    transform: translateY(1px);
}

.button .text {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.7rem;
    user-select: none;
    display: flex;
    align-items: center;
}

.button .icon {
    width: 1.4em;
    text-align: left;
}

.disabled,
.running {
    opacity: .5;
    color: #111;
    pointer-events: none;
}

.button.secondary.running {
    color: #ddd;
}

.running:after {
    background-color: rgba(250, 0, 0, 0.1);
}

.secondary.running:after {
    background-color: rgba(250, 255, 255, 0.3);
}

</style>