<template>
    <div>
        <div class="sc-cruddy-grid">

            <form v-on:submit.prevent="form.put()" class="sc-form widget pd">
                <div class="header"><span>Details</span></div>
                <sc-loading :form="form"></sc-loading>
                <sc-field :form="form" field="name">
                    <input v-model="form.fields.name" required/>
                </sc-field>
                <sc-field :form="form" field="network_name" description="read only" name="Network name">
                    <input v-model="form.fields.network_name" readonly/>
                </sc-field>
                <sc-field :form="form" field="url" name="Content Manager URL"
                          description='Base Url, without the "/ContentManager"'>
                    <input v-model="form.fields.url" required/>
                </sc-field>
                <sc-field :form="form" field="virtual_folder" name="Virtual Folder"
                          description='e.g. "ContentManager"'>
                    <input v-model="form.fields.virtual_folder" required/>
                </sc-field>
                <sc-field :form="form" field="user_name">
                    <input v-model="form.fields.user_name" required/>
                </sc-field>
                <sc-field :form="form" field="password">
                    <input v-model="form.fields.password" type="password"/>
                </sc-field>
                <sc-button type="submit" color="secondary" :running="form.processing" @clicked="form.put()">Save
                    Details
                </sc-button>

                <div class="delete">
                    <sc-button color="secondary" @clicked="deleting = true">Delete CM</sc-button>
                    <sc-modal @close="deleting = false" v-if="deleting">
                        <div class="delete-modal">
                            <div>You are about to delete <span style="font-weight:700;">{{form.fields.name}}</span>
                            </div>
                            <div style="margin-top:1.5em;">Are you sure?</div>
                            <sc-button color="secondary" @clicked="deleteCM">Yes, delete</sc-button>
                        </div>
                    </sc-modal>
                </div>
            </form>


            <form v-on:submit.prevent="settingsForm.post()" class="sc-form widget pd">
                <div class="header"><span>Settings</span></div>
                <sc-loading :form="settingsForm"></sc-loading>
                <sc-field :form="settingsForm" field="scheduled_task" name="active" description="Enable sync">
                    <sc-field-boolean :field.sync="settingsForm.fields.scheduled_task"></sc-field-boolean>
                </sc-field>
                <div class="sc-form-group"
                     v-if="settingsForm.fields.scheduled_task === true || settingsForm.fields.scheduled_task === 1">
                    <sc-field :form="settingsForm" field="scheduled_task_interval"
                              name="sync interval"
                              description="in minutes">
                        <input type="number" v-model="settingsForm.fields.scheduled_task_interval"
                               placeholder="in minutes"/>
                    </sc-field>
                </div>
                <sc-field :form="settingsForm" field="group_type"
                          description="Be careful changing this after the initial setup. Group details could be overwritten.">
                    <select v-model="settingsForm.fields.group_type" v-on:change="groupTypeChange">
                        <option value="groups">Groups</option>
                        <option value="metadata">Metadata</option>
                        <option value="state">Player State</option>
                        <option value="none">None</option>
                    </select>
                </sc-field>
                <sc-field :form="settingsForm" field="metadata_filter"
                          v-if="settingsForm.fields.group_type === 'metadata'">
                    <input v-model="settingsForm.fields.metadata_filter"
                           placeholder="e.g. Player.Store_Number"/>
                </sc-field>
                <!--                <sc-field :form="settingsForm" field="caching">-->
                <!--                    <sc-field-boolean :field.sync="settingsForm.fields.caching"></sc-field-boolean>-->
                <!--                </sc-field>-->
                <!--                <div class="sc-form-group"-->
                <!--                     v-if="settingsForm.fields.caching === true || settingsForm.fields.caching === 1">-->
                <!--                    <sc-field :form="settingsForm" field="caching_duration"-->
                <!--                              description="in minutes">-->
                <!--                        <input type="number" v-model="settingsForm.fields.caching_duration"/>-->
                <!--                    </sc-field>-->
                <!--                </div>-->
                <sc-field :form="settingsForm" field="email_notifications">
                    <sc-field-boolean :field.sync="settingsForm.fields.email_notifications"></sc-field-boolean>
                </sc-field>
                <div class="sc-form-group"
                     v-if="settingsForm.fields.email_notifications === true || settingsForm.fields.email_notifications === 1">
                    <sc-field :form="settingsForm" field="email_task_interval" name="email interval"
                              description="in minutes">
                        <input type="number" v-model="settingsForm.fields.email_task_interval"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="email_recipients"
                              description="email addresses, comma separated">
                        <input v-model="settingsForm.fields.email_recipients"/>
                    </sc-field>
                </div>
                <sc-field :form="settingsForm" field="timezone" name="server timezone">
                    <timezone-select :field.sync="settingsForm.fields.timezone"></timezone-select>
                </sc-field>
                <sc-field :form="settingsForm" field="hardware_logging">
                    <sc-field-boolean :field.sync="settingsForm.fields.hardware_logging"></sc-field-boolean>
                </sc-field>
                <div class="sc-form-group"
                     v-if="settingsForm.fields.hardware_logging === true || settingsForm.fields.hardware_logging === 1">
                    <sc-field :form="settingsForm" field="hardware_logging_settings.screenshot_interval"
                              name="Screenshot Interval" description="in minutes">
                        <input v-model="settingsForm.fields.hardware_logging_settings.screenshot_interval"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.update_interval"
                              name="Update Interval" description="in minutes">
                        <input v-model="settingsForm.fields.hardware_logging_settings.update_interval"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.cpu_threshold"
                              name="CPU Threshold" description="minimum usage % for warnings">
                        <input v-model="settingsForm.fields.hardware_logging_settings.cpu_threshold"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.cpu_duration"
                              name="CPU Duration" description="warn if usage exceeds duration (minutes)">
                        <input v-model="settingsForm.fields.hardware_logging_settings.cpu_duration"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.memory_threshold"
                              name="memory Threshold" description="minimum usage % for warnings">
                        <input v-model="settingsForm.fields.hardware_logging_settings.memory_threshold"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.memory_duration"
                              name="memory Duration" description="warn if usage exceeds duration (minutes)">
                        <input v-model="settingsForm.fields.hardware_logging_settings.memory_duration"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.disk_threshold"
                              name="disk Threshold" description="minimum usage % for warnings">
                        <input v-model="settingsForm.fields.hardware_logging_settings.disk_threshold"
                               type="number"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="hardware_logging_settings.disk_duration"
                              name="disk Duration" description="warn if usage exceeds duration (minutes)">
                        <input v-model="settingsForm.fields.hardware_logging_settings.disk_duration"
                               type="number"/>
                    </sc-field>
                </div>
                <sc-field :form="settingsForm" field="auto_ticketing">
                    <sc-field-boolean :field.sync="settingsForm.fields.auto_ticketing"></sc-field-boolean>
                </sc-field>
                <div class="sc-form-group"
                     v-if="settingsForm.fields.auto_ticketing === true || settingsForm.fields.auto_ticketing === 1">
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.apikey" name="API key">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.apikey"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.email" name="Contact Email">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.email"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.group" name="group">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.group"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.business_unit" name="business_unit">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.business_unit"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.subject" name="subject">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.subject"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.description" name="description">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.description"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.url" name="url">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.url"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.priority" name="priority">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.priority"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.category" name="category">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.category"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.status" name="status"
                              description="Initial status (one value)">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.status"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.closed_ticket_status"
                              name="Closed ticket statuses" description="Comma delimited">
                        <input v-model="settingsForm.fields.auto_ticketing_settings.closed_ticket_status"/>
                    </sc-field>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.auto_resolve" name="auto_resolve"
                              description="if the player comes back online">
                        <sc-field-boolean
                                :field.sync="settingsForm.fields.auto_ticketing_settings.auto_resolve"></sc-field-boolean>
                    </sc-field>
                    <template
                            v-if="settingsForm.fields.auto_ticketing_settings.auto_resolve === true || settingsForm.fields.auto_ticketing_settings.auto_resolve === 1">
                        <sc-field :form="settingsForm" field="auto_ticketing_settings.auto_resolve_within"
                                  name="auto_resolve_within" description="In minutes">
                            <input v-model="settingsForm.fields.auto_ticketing_settings.auto_resolve_within"
                                   type="number"/>
                        </sc-field>
                    </template>
                    <sc-field :form="settingsForm" field="auto_ticketing_settings.allow_reuse" name="allow_reuse"
                              description="reopen the previous ticket">
                        <sc-field-boolean
                                :field.sync="settingsForm.fields.auto_ticketing_settings.allow_reuse"></sc-field-boolean>
                    </sc-field>
                    <template
                            v-if="settingsForm.fields.auto_ticketing_settings.allow_reuse === true || settingsForm.fields.auto_ticketing_settings.allow_reuse === 1">
                        <sc-field :form="settingsForm" field="auto_ticketing_settings.allow_reuse_within"
                                  name="allow_reuse_within" description="In minutes">
                            <input v-model="settingsForm.fields.auto_ticketing_settings.allow_reuse_within"
                                   type="number"/>
                        </sc-field>
                    </template>
                </div>

                <sc-button type="submit" color="secondary" :running="settingsForm.processing"
                           @clicked="settingsForm.post()">
                    Save Settings
                </sc-button>
            </form>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$sform.createForm({
          fields: {
            name: '',
            url: '',
            virtual_folder: '',
            user_name: '',
            password: '',
            network_name: ''
          },
          success: () => {
            this.$emit('passed');
            this.$notify.success('Details saved')
          },
          loading: true,
          url: `content-manager/${this.$route.params.contentmanager_id}/details`,
          url_put: `content-manager/${this.$route.params.contentmanager_id}`,
        }),
        settingsForm: this.$sform.createForm({
          fields: {
            group_type: '',
            timezone: '',
            metadata_filter: '',
            scheduled_task: true,
            scheduled_task_interval: 60,
            caching: false,
            caching_duration: 10,
            email_notifications: false,
            email_task_interval: 0,
            email_recipients: '',
            hardware_logging: 0,
            hardware_logging_settings: {
              cpu_threshold: 90,
              cpu_duration: 10,
              memory_threshold: 90,
              memory_duration: 10,
              disk_threshold: 90,
              disk_duration: 10,
              screenshot_interval: 10,
              update_interval: 10,
            },
            auto_ticketing: false,
            auto_ticketing_settings: {
              apikey: '',
              email: '',
              group: '',
              business_unit: '',
              subject: '',
              description: 'Created by Monitoring Dashboard',
              url: 'https://support.stratacache.com/api/json/',
              priority: 'To Be Classified',
              category: 'Monitoring',
              status: 'open',
              closed_ticket_status: 'closed, resolved',
              auto_resolve: 0,
              auto_resolve_within: 10,
              allow_reuse: 0,
              allow_reuse_within: 10,
            }

          },
          loading: true,
          url: `content-manager/${this.$route.params.contentmanager_id}/settings`
        }),

        deleting: false,
      }
    },

    mounted() {
      this.form.get()
        .catch((error) => {
          if (error.response.status === 403) {
            this.$notify.error('Not authorized');
            this.$router.push('/')
          }
        });
      this.settingsForm.get();
    },

    methods: {
      groupTypeChange(element) {
        if (element.target.value === 'metadata') {
          this.settingsForm.fields.metadata_filter = ''
        }
      },

      deleteCM() {
        this.$talker.api.delete(`content-manager/${this.$route.params.contentmanager_id}`)
          .then(res => {
            this.$notify.success('Content Manager deleted')
            this.$router.replace('/')
          })
      }
    }
  }
</script>

<style scoped lang="scss">
    .delete {
        margin-top: 4em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .delete-modal {
        text-align: center;

        > div {
            margin: 0.5em 0;
            font-size: 1.2em;
        }
    }
</style>