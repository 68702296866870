<template>
    <div>
        <portal to="banner">
            <sc-page-header>
                <cm-select :key="$route.params.contentmanager_id"></cm-select>
            </sc-page-header>
        </portal>
        <div class="width-container pd">
            <sc-tabs :back="`/content-managers/${$route.params.contentmanager_id}/details`">
                <sc-tab :current="current" name="main" icon="info" to="main"></sc-tab>
                <sc-tab :current="current" name="members" icon="user" to="members"></sc-tab>
                <sc-tab :current="current" name="schedule" icon="clock" to="schedule"></sc-tab>
                <sc-tab :current="current" name="ticket fields" icon="link" to="ticket-fields"></sc-tab>
                <sc-tab :current="current" name="error blacklist" icon="list" to="error-blacklist"></sc-tab>
            </sc-tabs>
            <div class="panel">
                <children-route :animate="true" @passed="fetch()"></children-route>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'index',

    data() {
      return {
        item: undefined,
      }
    },

    computed: {
      current() {
        return this.$route.meta.name;
      }
    },

    mounted() {
      this.fetch()
    },

    methods: {
      fetch() {
        this.$talker.api.get(`content-manager/${this.$route.params.contentmanager_id}/details`)
          .then(res => {
            this.item = res.data;
          })
      }
    }

  }
</script>
