<template>
    <div class="chart">
        <div class="pdt graph-container">
            <div>
                <canvas ref="graph" width="400" height="200"></canvas>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment-timezone'
import Chart from 'chart.js'

export default {
  name: "chart-interactions",

  props: {
    player_id: {},
    dateRange: {}
  },

  data() {
    return {
      records: [],
      graph: undefined,

      config: {
        type: 'line',
        data: {
          datasets: [],
        },
        options: {
          legend: {
            display: false
          },
          plugins: {
            filler: {
              propagate: false
            }
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            displayColors: false,
            callbacks: {
              title: function (tooltipItems, data) {
                //Return value for title
                var date = moment(tooltipItems[0].xLabel);
                return [date.format('DD/MM/YYYY')];
              },
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                let value = parseFloat(tooltipItem.yLabel).toFixed(0) || '';
                return label + ': ' + value;
              }
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'H:mm'
                }
              },
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: '#555',
                callback: function (value, index, values) {
                  if (values[index]) {
                    var date = moment(values[index].value);
                  } else {
                    return value;
                  }
                  if (date.format('H') === '0') {
                    return [value, date.format('ddd')];
                  } else {
                    return value;
                  }
                },
                stepSize: 10,
              },
            }],
            yAxes: [{
              gridLines: {
                display: true,
              },
              ticks: {
                fontColor: '#555',
                min: 0,
                fontSize: 10,
                callback: function (value, index, values) {
                  if (index % 2 === 0) {
                    if (value > 0 && value < 1) {
                      return parseFloat(value).toFixed(2);
                    }
                    return parseFloat(value).toFixed(2);
                  }
                }
              },
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },

      setNames: [
        {
          name: 'userScan',
          color: '#ff4800',
          icon: 'times',
          active: true,
        },
        {
          name: 'userPlayMedia',
          color: '#4e73e2',
          icon: 'times',
          active: true,
        },
      ],

    }
  },


  watch: {
    dateRange() {
      this.fetch();
    },
    setNames() {
      this.fetch();
    }
  },

  mounted() {
    this.$nextTick(function () {
      this.graph = new Chart(this.$refs.graph, this.config);
    });
    this.fetch();
  },

  methods: {
    fetch() {

      let payload = {
        start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
        end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day')
      };

      if (this.player_id) {
        payload['player_id'] = this.player_id;
      }

      this.$talker.api.post(`reports/interactions`, payload)
        .then(res => {
          this.records = res.data;
          this.setData();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            this.$notify.error('Not authorized');
            this.$router.push('/')
          } else {
            console.error(error);
          }
        });
    },

    setData() {

      let start = moment.tz(this.dateRange.startDate, 'UTC').startOf('day');
      let end = moment.tz(this.dateRange.endDate, 'UTC').endOf('day');
      let diff = end.diff(start, 'days');
      this.config.options.scales.xAxes[0].time.unit = (diff > 1) ? 'day' : 'hour';

      this.config.data.datasets = [];

      for (let i = 0; i < this.setNames.length; i++) {

        let setName = this.setNames[i].name;
        let data = [];

        for (let x = 0; x < this.records.length; x++) {
          let record = this.records[x];
          let dataPoint = {
            x: moment(record.created_at),
            y: 0
          };
          dataPoint.y = record[setName];
          data.push(dataPoint);
        }

        let dataSet = {
          label: setName,
          data: data,
          backgroundColor: this.setNames[i].color,
          borderColor: this.setNames[i].color,
          fill: false,
          lineTension: 0,
          borderWidth: 1,
        };
        this.config.data.datasets.push(dataSet);
      }

      this.graph.update();
    },

    calculatePercentage(states) {
      let total = states.positive + states.neutral + states.negative;
      let count = states.positive + states.neutral;
      if (total > 0) {
        let perc = count / total * 100;
        if (perc === 100) {
          return 99.99;
        } else {
          return perc;
        }
      } else {
        return 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chart {
    background: #fff;
    box-shadow: $shading;
    border-radius: 0.5em;
}

</style>


<style lang="scss">

</style>