<template>
    <div>
        <sc-table style="padding:0;" :table="table" :clickable="false"></sc-table>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "chart-top-media",

  props: {
    dateRange: {},
    player_id: {},
  },

  data() {
    return {
      table: this.$stable.createTable({
        name: 'top media',
        sortBy: 'total',
        columns: {
          media_id: {name: 'id', width: 50},
          name: {fill: true},
          total: {width: 150}
        }
      })
    }
  },

  watch: {
    dateRange() {
      this.fetch();
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {

    fetch() {
      let payload = {
        start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
        end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day')
      };

      if (this.player_id) {
        payload['player_id'] = this.player_id;
      }

      this.$talker.api.post(`reports/top-media`, payload)
        .then(res => {
          this.table.data = res.data;
          this.table.hasData = true;
          this.table.prefetch();
        })
    },

    view(row) {
      this.$router.push(`/manager/player/${row.player_id}/interactions`)
    }

  }
}
</script>

<style scoped lang="scss">
::v-deep .total .top {
    font-weight: 700;
}
</style>